import request from "@/utils/service.js"
export default class staffService {
  
  list(data) {
    return request({
      url: 'm_getStaffList',
      method: 'post',
      data: data
    })
  }
  detail(data) {
    return request({
      url: 'm_getStaff',
      method: 'post',
      data: data
    })
  }
  add(data) {
    return request({
      url: 'm_addStaff',
      method: 'post',
      data: data
    })
  }
  update(data) {
    return request({
      url: 'm_updateStaff',
      method: 'post',
      data: data
    })
  }
  delete(data) {
    return request({
      url: 'm_deleteStaff',
      method: 'post',
      data: data
    })
  }
}
